<template>
	<div class="new-total">
		<div class="product-bar">
			<HomeTitleBar v-if="$app == 'kh'" :type="Number('9')" title="聚焦康辉" desc="" :tab_array="[]" :hideMore="true">
			</HomeTitleBar>
			<HomeTitleBar v-else-if="$app == 'dy'" :type="Number('9')" title="聚焦大运" desc="" :tab_array="[]"
				:hideMore="true"></HomeTitleBar>
			<div class="news-list">
				<div class="news-item" v-for="(item, index) in news_list" :key="item.id" @click="clickNews(index)">
					<div class="point"></div>
					<div v-if="item.typeName == '活动'" class="news-type-orange">
						【活动】
					</div>
					<div v-else class="news-type-blue">【报道】</div>
					<div class="news-title">{{ item.newsTitle }}</div>
				</div>
			</div>
		</div>
		<el-pagination class="page" @current-change="currentChange" background :current-page="page"
			:page-size="max_page" layout="prev, pager, next" :total="total">
		</el-pagination>
	</div>
</template>

<script>
	import {
		getNewsList
	} from "@/api/index";
	import HomeTitleBar from "../components/Home_Title_Bar.vue";
	export default {
		components: {
			HomeTitleBar,
		},
		data() {
			return {
				page: 1,
				max_page: 15,
				total: 0,
				news_list: [],
			};
		},
		created() {
			this.getNewsList();
		},
		methods: {
			clickNews(e) {
				let org = this.$route.query.org ? this.$route.query.org : ''
				window.open("/NewsDetail?id=" + this.news_list[e].id + "&org=" + org, "_blank");
			},

			//当前页码改变
			currentChange(e) {
				this.page = e;
				this.getNewsList();
			},

			getNewsList() {
				getNewsList(this.page, this.max_page).then((res) => {
					this.total = res.total;
					this.news_list = res.objects;
				});
			},
		},
	};
</script>

<style lang='less' scoped>
	.new-total {
		display: flex;
		width: 100vw;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		padding-bottom: 50px;

		.product-bar {
			display: flex;
			z-index: 1;
			width: 1200px;
			margin-top: 20px;
			flex-direction: column;

			div.news-list {
				display: flex;
				flex-direction: column;
				width: 100%;
				background-color: #fff;
				height: 100%;
				justify-content: space-around;
				box-sizing: border-box;
				padding-bottom: 50px;
				border-top: 1px solid #e5e5e5;

				div.news-item {
					display: flex;
					box-sizing: border-box;
					height: 48px;
					cursor: pointer;
					align-items: center;
					border-bottom: 1px solid #e5e5e5;

					.point {
						width: 5px;
						height: 5px;
						background: #e2e2e2;
						border-radius: 50%;
						opacity: 1;
					}

					div.news-type-orange {
						font-size: 16px;
						font-weight: 400;
						line-height: 100%;
						color: #fd8e60;
						opacity: 1;
					}

					div.news-type-blue {
						font-size: 16px;
						font-weight: 400;
						line-height: 100%;
						color: #459eeb;
						opacity: 1;
					}

					div.news-title:hover {
						color: var(--hover_color);
					}

					div.news-title {
						width: calc(100% - 56px - 15px);
						font-size: 16px;
						font-weight: 400;
						line-height: 100%;
						color: #666666;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						opacity: 1;
					}
				}
			}
		}
	}
</style>
